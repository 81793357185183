@import url("../src/assets/css/bootstrap.css");
@import url("../src/assets/css/bootstrap-extended.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-family: 'Montserrat' !important;
}

.btn-blue-app {
  max-width: 320px;
  width: 100% !important;
  padding: 10px !important;
  border: none !important;
  color: #fff !important;
  font-size: 16px !important;
  background: #0B58A4;
  border-radius: 8px !important;
  opacity: 1 !important;
}

.btn-blue-app:hover {
  background: #054484;
}

.text-10 {
  font-size: 10px !important;
}

.text-10-bold {
  font-size: 10px !important;
  font-weight: bold !important;
}

.text-12 {
  font-size: 12px !important;
}

.text-12-bold {
  font-size: 12px !important;
  font-weight: bold !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-14-bold {
  font-size: 14px !important;
  font-weight: bold !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-16-bold {
  font-size: 16px !important;
  font-weight: bold !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-18-bold {
  font-size: 18px !important;
  font-weight: bold !important;
}

.text-20 {
  font-size: 20px !important;
}

.text-20-bold {
  font-size: 20px !important;
  font-weight: bold !important;
}

.text-28 {
  font-size: 28px !important;
}

.text-28-bold {
  font-size: 28px !important;
  font-weight: bold !important;
}

.text-activo {
  font-size: 10px !important;
  font-weight: bold !important;
  color: #018D29 !important;
}

.text-reprobado {
  font-size: 10px !important;
  font-weight: bold !important;
  color: #FF0000 !important;
}

.text-sincal {
  font-size: 10px !important;
  font-weight: bold !important;
  color: #808080 !important;
}

.text-encurso {
  font-size: 10px !important;
  font-weight: bold !important;
  color: #ED8F05 !important;
}

.text-blue {
  color: #0B58A4 !important;
}

.text-grey {
  color: #A3A4A4 !important;
}

.text-calypso {
  color: #5FCDF5 !important;
}

.box__info {
  background: #D0EFF8;
}

.box__capa {
  border: 2px solid #DFF4FA;
}

.box__title___capa {
  border-bottom: 2px solid #DFF4FA;
  position: sticky;
  top: -1px;
  background: #fff;
}

.box__scroll___capa {
  max-height: 283px !important;
  overflow-y: auto;
}

.underline__capa {
  border-bottom: 2px solid #DFF4FA;
}

.box__activo {
  background-color: #F3FEF2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 2px 10px;
}

.box__reprobado {
  background-color: #fef1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 2px 10px;
}

.box__sincalificacion {
  background-color: #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 2px 10px;
}

.box__encurso {
  background-color: #FFFAF0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 2px 10px;
}

.img__fondo {
  background-image: url('./assets/img/img_fondo.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
}

.box__login {
  max-width: 400px;
  background: rgba(30, 35, 67, 0.82);
  border-radius: 14px;
}

.input__login {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background: transparent;
  border-radius: 8px;
}

.box__step {
  max-width: 360px;
}

.circle-number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  color: #fff;
  width: 24px !important;
  height: 24px !important;
  background: #3E5AAD;
}

.line-tep-left {
  height: 2px;
  background: #3E5AAD;
  margin: 0px 0px 0px 50px;
  opacity: 1;
  position: relative;
  top: -12px;
}

.line-tep-right {
  height: 2px;
  background: #3E5AAD;
  margin: 0px 50px 0px 0px;
  opacity: 1;
  position: relative;
  top: -12px;
}

.img__subfondo {
  background-image: url('./assets/img/img_fondo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.box__gradient {
  background: rgb(107,153,200);
  background: linear-gradient(180deg, rgba(107,153,200,0.80) 0%, rgba(11,88,164,0.80) 100%);
}

.box__preguntas {
  background: #0B58A4;
}

.underline__pregunta {
  border-bottom: 2px solid #5589bc;
}

.respuesta__cerrada {
  position: relative;
  height: 0px !important;
  overflow: hidden;
}

.respuesta__abierta {
  height: fit-content !important;
}

.rotate {
  transform: rotate(180deg);
}

.box__consper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.underline__datos {
  border-bottom: 1px solid #3C3850;
}

.info__interior > div {
  display: flex;
  align-items: start;
}

.img__rrss {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
